import React, { Suspense, useEffect, useRef, useState } from "react";
import { Box, responsiveFontSizes, Typography, useMediaQuery, useTheme } from "@mui/material"

import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import FloramiModel from "./graphics/FloramiModel";
import { Vector3, AxesHelper, BoxGeometry, Mesh, MeshBasicMaterial, Matrix4 } from "three";

import ModelLoading from "../common/ModelLoading";

import "../App.css";


// Start Page occupies an entire screen.
const StartPage = () => {
    return (
        <div className="page-content">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
            }}>

                {/* Div containing title box and florami render */}
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                    width: "90vw",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: {
                        xs: "20px",
                        sm: "50px",
                        md: "100px",
                        lg: "80px",
                    },
                    paddingBottom: "5%"
                }}>
                    <FloramiContainer />
                    <TitleContainer />
                </Box>
            </div >
        </div>
    )
}

const TitleContainer = () => {
    const theme = useTheme();
    const xsMatch = useMediaQuery(theme.breakpoints.up('xs'));
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));
    const mdMatch = useMediaQuery(theme.breakpoints.up('md'));
    const lgMatch = useMediaQuery(theme.breakpoints.up('lg'));
    const xlMatch = useMediaQuery(theme.breakpoints.up('xl'));

    return <div>
        {
            (
                lgMatch ?
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: 110,
                            textAlign: "center",
                            width: "100%",       
                            display: "flex",     
                            justifyContent: "center",  
                            alignItems: "center",      
                        }}
                        align='center'
                        variant="title"
                        lineHeight='0.9'>SAY HELLO TO<br />YOUR VIRTUAL<br />PLANT PAL
                    </Typography> :
                mdMatch ?
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: 90,
                            textAlign: "center",
                            width: "100%",       
                            display: "flex",     
                            justifyContent: "center",  
                            alignItems: "center",  
                            paddingBottom: "5%"
                        }}
                        align='center'
                        variant="title"
                        lineHeight='0.9'>SAY HELLO TO<br />YOUR VIRTUAL<br />PLANT PAL
                    </Typography> :
                smMatch ?
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: 60,
                            textAlign: "center",
                            width: "100%",       
                            display: "flex",     
                            justifyContent: "center",  
                            alignItems: "center",  
                            paddingBottom: "5%"
                        }}
                        align='center'
                        variant="title"
                        lineHeight='0.9'>SAY HELLO<br />TO YOUR<br />VIRTUAL<br />PLANT PAL
                    </Typography> :
                    <Typography
                        sx={{
                            position: "relative",
                            fontSize: 50,
                            textAlign: "center",
                            width: "100%",       
                            display: "flex",     
                            justifyContent: "center",  
                            alignItems: "center",  
                            paddingBottom: "15%"        // Pushes up to fill the space
                        }}
                        align='center'
                        variant="title"
                        lineHeight='0.9'>SAY HELLO<br />TO YOUR<br />VIRTUAL<br />PLANT PAL
                    </Typography>
            )
        }
    </div>
}

const FloramiContainer = () => {
    const theme = useTheme();
    const xsMatch = useMediaQuery(theme.breakpoints.up('xs'));
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));
    const mdMatch = useMediaQuery(theme.breakpoints.up('md'));
    const lgMatch = useMediaQuery(theme.breakpoints.up('lg'));
    const xlMatch = useMediaQuery(theme.breakpoints.up('xl'));

    const [renderSize, setRenderSize] = useState(0);
    useEffect(() => {
        if (smMatch !== true) {
            setRenderSize(11);
        } else if (mdMatch !== true) {
            setRenderSize(10);
        } else {
            setRenderSize(8);
        }
    }, [smMatch, mdMatch]);

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingBottom: mdMatch ? "3%" : "0%"
        }}>
            <Suspense fallback={<ModelLoading />}>
                <Canvas linear>
                    <PerspectiveCamera makeDefault
                        position={[0, -1, renderSize]}
                        rotation={[0, 0, 0]} />

                    <ambientLight />
                    <pointLight position={[0, 0, 100]}
                        intensity={0.8}
                    />

                    <FloramiModel
                        isIdle={false}
                        // Scale the model up for larger screens,, since render size has limitations on how small it can get
                        scale={lgMatch ? 1.1 : 1}     
                        position={[0, -3.5, 0]}
                        rotation={[0, 0, 0]}>
                    </FloramiModel>
                </Canvas>
            </Suspense>
        </div>
    );
}

export default StartPage;