import './App.css';

import Home from './pages/Home'
import Info from './pages/Info'
import Blum from './pages/Blum';
import NavBar from './components/NavBar'
import ConnectBar from './components/ConnectBar'

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom'
import DownloadRedirect from './pages/DownloadRedirect';
import LocalHTMLPage from './pages/LocalHTMLPage';
import LinkRedirect from './pages/LinkRedirect';

const theme = createTheme({
    palette: {
        primary: {
            main: '#268513', // Green
            contrastText: '#FFFFFF',
            blackText: '#000000'
        },
        secondary: {
            main: '#EEB3C1' // Pink
        },
        background: {
            main: '#FFFFFF' // Off White
        },
    },
    typography: {
        fontFamily: [
            'Nunito',
            'Poppins'
        ].join(','),
        h1: {
            fontWeight: 800,
            color: '#3B9128',
            fontSize: 112,
            textTransform: 'uppercase',
        },
        title: {
            fontFamily: 'Nunito',
            fontWeight: 800,
            color: '#3B9128',
            fontSize: 90,
            textTransform: 'uppercase',
        },
        h2: {
            fontWeight: 800,
            fontSize: 48,
            color: '#3B9128',
            textTransform: 'uppercase',
        },
        h3: {
            fontWeight: 600,
            fontSize: 36,
            color: '#3B9128'
        },
        h4: {
            fontWeight: 300,
            fontSize: 28,
            color: '#3B9128'
        },
        h5: {
            fontWeight: 500,
            fontFamily: 'Poppins',
            fontSize: 24,
            color: '#3B9128'
        },
        body1: {
            fontWeight: 400,
            fontSize: 16,
            fontFamily: 'Poppins',
            color: '#3B9128'
        },
        body2: {
            fontWeight: 300,
            fontFamily: 'Poppins',
            color: '#3B9128',
            fontSize: 24
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 24,
            fontFamily: 'Poppins',
            color: '#3B9128'
        },
        navButtonText: {
            fontSize: 16,
            fontWeight: 300
        }
    }
})

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    }, 
    {
        path: "/download",
        element: <DownloadRedirect isPlantShop={false} />
    }
])

function App() {
    const connectBarShow = window.location.pathname !== "/blum";
    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <RouterProvider router={router}></RouterProvider>
                {connectBarShow && <ConnectBar />}
            </div>
        </ThemeProvider>
    );
}

export default App;
