import { Button, Typography, Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ModelLoading from "../common/ModelLoading";
import { getMobileOperatingSystem } from "../common/Utils";


function PlantShopQR(props) {
    const device = getMobileOperatingSystem();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (device === "iOS") {
            if (props.isPlantShop === true) {
                window.fbq("trackCustom", "PlantShop_QR_iOS");
            } else {
                window.fbq("trackCustom", "External_Download_iOS");
            }
            window.location = "https://apps.apple.com/us/app/floramis/id6444943805";
            // window.location = "https://forms.gle/BYLLrrUXGZ7wXXBeA";

        } else if (device === "Android") {
            if (props.isPlantShop === true) {
                window.fbq("trackCustom", "PlantShop_QR_Android");
            } else {
                window.fbq("trackCustom", "External_Download_Android");
            }
            window.location = "market://details?id=com.Floramis.Floramis";

        } else {
            if (props.isPlantShop === true) {
                window.fbq("trackCustom", "PlantShop_QR_Unknown_Device");
            } else {
                window.fbq("trackCustom", "External_Download_Unknown_Device");
            }
            // window.location = "https://floramis.com";
            setShowAlert(true); // Show alert to inform users to use a mobile device
        }           
    }, [device, props.isPlantShop]);

    const handleCloseAlert = () => {
        setShowAlert(false); 
        window.location = "https://floramis.com";  // Reload the page
    };

    return (
        <div className='page'>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh"
            }}>
                <ModelLoading />
                <Snackbar
                    open={showAlert}
                    autoHideDuration={5000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={handleCloseAlert} variant="filled" severity="info" sx={{ width: "100%" }}>
                        Please try accessing this link using your mobile device.
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default PlantShopQR;