import { Typography, Box, ButtonBase, Button, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import "../Animations.css";
import { getMobileOperatingSystem } from '../common/Utils';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

// TODO: Provide actual links for buttons
const Trial = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const appStoreButtonStyle = {
        backgroundColor: "primary.main",
        padding: isSmallScreen ? "8% 12%" : "3% 8%",        // Different padding for small screens
        boxShadow: 5,
        borderRadius: "80px",
        ':hover': {
            transform: "scale(1.05)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        },
        ':not(:hover)': {
            transform: "scale(1)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        }
    }

    return (
        <div className="page-content">
            <Box id="trial" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, pb: 5, pt: 2 }}>
                <DownloadButton 
                    sx={appStoreButtonStyle}
                    href="https://floramis.com/download"     
                    // href="http://localhost:3000/download"       // To test locally
                ></DownloadButton>
            </Box>
        </div>
    )
}

const CustomButton = (props) => {
    const hoverEffect = {
        width: props.width,
        ':hover': {
            transform: "scale(1.05)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        },
        ':not(:hover)': {
            transform: "scale(1)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        }
    };


    return (
        <ButtonBase
            sx={{ ...hoverEffect, ...props.sx}}
            href={props.href}
            disableRipple={true}
            target='_blank'
            onClick={props.onClick}
        >
            <img style={{ width: props.width }} src={props.buttonImage} alt='Button icon' />
        </ButtonBase>
    )
}

const DownloadButton = (props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ButtonBase
            sx={props.sx}
            href={props.href}
            disableRipple={true}
            // target='_blank'
            onClick={props.onClick}
        >
            {isSmallScreen ? 
                <Typography color="white" lineHeight='0.9' variant='h3' fontFamily='Poppins'>DOWNLOAD NOW</Typography> 
            :
                <Typography color="white" lineHeight='0.9' variant='h2' fontFamily='Poppins'>DOWNLOAD NOW</Typography>
            }
        </ButtonBase>
    )
}

export { CustomButton };
export default Trial;